// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-background-history-js": () => import("./../../../src/pages/background-history.js" /* webpackChunkName: "component---src-pages-background-history-js" */),
  "component---src-pages-bosnia-js": () => import("./../../../src/pages/bosnia.js" /* webpackChunkName: "component---src-pages-bosnia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kongo-js": () => import("./../../../src/pages/kongo.js" /* webpackChunkName: "component---src-pages-kongo-js" */),
  "component---src-pages-launching-soon-js": () => import("./../../../src/pages/launching-soon.js" /* webpackChunkName: "component---src-pages-launching-soon-js" */),
  "component---src-pages-mali-js": () => import("./../../../src/pages/mali.js" /* webpackChunkName: "component---src-pages-mali-js" */),
  "component---src-pages-suez-js": () => import("./../../../src/pages/suez.js" /* webpackChunkName: "component---src-pages-suez-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */)
}

